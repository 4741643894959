import { lazy } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { AsyncRoute } from '../';
import { Header } from '@parkhub/parkhub-ui';
import { signOut, getAuthUser, getFeatureAccess } from '../../utils';
import ParkhubLogo from '../../assets/parkhub-logo-white.svg';

const Home = lazy(() => import('../Home/Home'));
const access = getFeatureAccess();

const Layout = () => {
  const navigate = useNavigate();
  const authUser = getAuthUser();

  return (
    <>
      <Header
        authUser={authUser}
        access={access}
        signOut={() => signOut()}
        logoSrc={ParkhubLogo}
        logoWidth={143}
        appMenuActive
        logoClick={() => navigate('/')}
      />
      <main id="layout">
        <div>
          <Routes>
            <Route
              path="/"
              element={<AsyncRoute component={Home} />}
            />
          </Routes>
        </div>
      </main>
    </>
  );
};

export default Layout;
