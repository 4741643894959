import {
  HighchartsProvider,
  HighchartsChart,
  Chart,
  XAxis,
  YAxis,
  Title,
  Subtitle,
  Legend,
  LineSeries,
  Caption,
  PieSeries,
  Tooltip
} from 'react-jsx-highcharts';
import Highcharts from 'highcharts';

//Optionally Add additional highcharts modules
import addHighchartsMore from 'highcharts/highcharts-more';
import applyExporting from 'highcharts/modules/exporting';
import applyOffline from 'highcharts/modules/offline-exporting';

addHighchartsMore(Highcharts);
applyExporting(Highcharts);
applyOffline(Highcharts);

const Home = () => {
  const plotOptions = {
    series: {
      pointStart: 2010
    }
  };

  return (
    <div
      id="home"
      className="wrapper"
    >
      <section>
        <HighchartsProvider
          Highcharts={Highcharts}
        >
          <HighchartsChart
            plotOptions={plotOptions}
          >
            <Chart />
            <Tooltip />
            <Title>
              Solar Employment Growth by Sector, 2010-2016
            </Title>
            <Subtitle>
              Source: thesolarfoundation.com
            </Subtitle>
            <Legend
              layout="vertical"
              align="right"
              verticalAlign="middle"
            />
            <XAxis>
              <XAxis.Title>Time</XAxis.Title>
            </XAxis>
            <YAxis>
              <YAxis.Title>Number of employees</YAxis.Title>
              <LineSeries
                name="Installation"
                data={[43934, 52503, 57177, 69658, 97031, 119931, 137133, 154175]}
              />
              <LineSeries
                name="Manufacturing"
                data={[24916, 24064, 29742, 29851, 32490, 30282, 38121, 40434]}
              />
              <LineSeries
                name="Sales & Distribution"
                data={[11744, 17722, 16005, 19771, 20185, 24377, 32147, 39387]}
              />
              <LineSeries
                name="Project Development"
                data={[null, null, 7988, 12169, 15112, 22452, 34400, 34227]}
              />
              <LineSeries
                name="Other"
                data={[12908, 5948, 8105, 11248, 8989, 11816, 18274, 18111]}
              />
            </YAxis>
            <Caption
              align="center"
            >
              The installation sector sees the most growth.
            </Caption>
          </HighchartsChart>
        </HighchartsProvider>
      </section>
      <br />
      <section>
        <HighchartsProvider
          Highcharts={Highcharts}
        >
          <HighchartsChart
            chart={{ height: '500px' }}
            className="donut-chart"
          >
            <Chart />
            <Tooltip
              backgroundColor="white"
            />
            <Title
              align="center"
              className="donut-title"
              verticalAlign="middle"
              y={-5}
              style={{
                fontSize: '18px'
              }}
            >
              This Is A Pie Chart
            </Title>
            <Subtitle
              align="center"
              className="donut-subtitle"
              verticalAlign="middle"
              y={15}
              style={{
                fontSize: '14px',
                color: 'rgba(0, 0, 0, .55)'
              }}
            >
              This is a subtitle
            </Subtitle>
            <PieSeries
              borderWidth={0}
              center={['50%', '50%']}
              data={[['apples', 11], ['bananas', 50], ['oranges', 29], ['strawberries', 10]]}
              dataLabels={{ enabled: false }}
              depth={3}
              id="pie-series"
              innerSize="85%"
              size="450px"
            />
          </HighchartsChart>
        </HighchartsProvider>
      </section>
      <br />
      <section>
        <p>
          <code>React-jsx-highcharts@5</code> is still in alpha release to make it compatible with <code>React@18</code>.
        </p>
        <p>
          Check <a href="https://codesandbox.io/s/github/whawker/react-jsx-highcharts-examples">here for Examples</a> with <code>React@17+</code>
        </p>
        <p>
          Check <a href="https://github.com/whawker/react-jsx-highcharts/tree/master/packages/react-jsx-highcharts#demos"> here for React-jsx-highcharts docs</a>
        </p>
      </section>
    </div>
  );
};

export default Home;
